<template>
  <v-app>
    <Dashboard></Dashboard>
  </v-app>
</template>

<style>
</style>
<script>
import Dashboard from './views/Dashboard.vue';

export default {
  components: { Dashboard },
};
</script>
